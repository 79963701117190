<template>
  <div>
    <div class="worksite">
      <DateSelector
          @search="getList"
          :title="$t('top-select-site-statistics-member')"
      />
      <div class="box one">
        <LineBarChart
            :chartDate="chartDate"
            :support="support"
            :controller="controller"
            :preData="preData"
        />
      </div>
      <Compare
          :refTotalData="refTotalData"
          :preTotalData="preTotalData"
          :refLiveData="refLiveData"
          :preLiveData="preLiveData"
          :refSumSup="refSumSup"
          :refSumCtrl="refSumCtrl"
          :preSumSup="preSumSup"
          :preSumCtrl="preSumCtrl"
          :ration="ration"
          :resultValue="'worksite3'"
      />
    </div>
  </div>
</template>

<script>
// import LineChart from "@/components/charts/LineChart3";
import LineBarChart from "@/components/charts/LineBarChart";
import Compare from "@/components/statistics/Compare";
import DateSelector from "@/components/statistics/DateSelector";
import moment from "moment";
import { fetchStatisticsWorkerList } from "@/api/statistics";
export default {
  components: { DateSelector, Compare, LineBarChart },
  name: "Worksite3",
  data() {
    return {
      moment: moment,
      chartDate: [],
      refData: [],
      preData: [],
      refTotalData: 0,
      preTotalData: 0,
      refLiveData: [],
      preLiveData: [],
      ration: 0,
      startDate: "",
      endDate: "",
      refSumSup: 0,
      refSumCtrl: 0,
      preSumSup: 0,
      preSumCtrl: 0,
      current: 0,
      controller: [],
      support: [],
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 3, sub: 1 });
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("MM-DD");
    },
    getList(data) {
      let params = {
        dateRangeValue: data.dateRangeValue,
      };

      if (params.dateRangeValue == null) {
        params["dateRange.from"] = data.startYmd ? moment(data.startYmd).format("YYYY-MM-DD") : null;
        params["dateRange.to"] = data.endYmd ? moment(data.endYmd).format("YYYY-MM-DD") : null;
      }

      this.chartDate = [];
      this.refData = [];
      this.preData = [];
      this.refLiveData = [];
      this.preLiveData = [];
      this.refTotalData = 0;
      this.preTotalData = 0;
      this.refSumSup = 0;
      this.refSumCtrl = 0;
      this.preSumSup = 0;
      this.preSumCtrl = 0;
      this.controller = [];
      this.support = [];

      fetchStatisticsWorkerList(params).then((res) => {
        this.refLiveData = res.data.data.reference;
        this.preLiveData = res.data.data.previous;
        this.refTotalData = res.data.data.referenceTotal;
        this.preTotalData = res.data.data.previousTotal;
        this.refLiveData.forEach((item) => {
          this.chartDate.push(this.customFormatter(item.date));
          this.refSumSup += item.support;
          this.refSumCtrl += item.controller;
          this.refData.push(item.totalData);
          this.support.push(item.support);
          this.controller.push(item.controller);
        });
        this.preLiveData.forEach((item) => {
          this.preSumSup += item.support;
          this.preSumCtrl += item.controller;
          this.preData.push(item.totalData);
        });
        this.ration = this.preTotalData ===  0
            ? ( this.refTotalData === 0 ? 0 : 100)
            : Math.floor(((this.refTotalData-this.preTotalData) / this.preTotalData) * 100);
      });
    },
  },
};
</script>

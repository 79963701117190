<template>
  <VueApexCharts
    ref="chart"
    width="100%"
    height="190"
    :options="chartOptions"
    :series="series"
  >
  </VueApexCharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import i18n from "@/lang";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    chartDate: {
      type: Array,
    },
    support: {
      type: Array,
    },
    controller: {
      type: Array,
    },
    preData: {
      type: Array,
    },
  },
  data() {
    return {
      series: [
        {
          name: i18n.t("site-statistics-column-mem"),
          type: "column",
          data: this.support,
        },
        {
          name: i18n.t("site-statistics-column-operator"),
          type: "column",
          data: this.controller,
        },
        {
          name: i18n.t("site-statistics-prev-period"),
          type: "line",
          data: this.preData,
        },
      ],
      chartOptions: {
        chart: {
          type: "area",
          height: 200,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 0, 2],
          curve: "straight",
          colors: ["#BF4707"],
        },
        legend: {
          markers: {
            fillColors: ["#4371C3", "#A8D18E", "#BF4707"],
          },
        },
        fill: {
          type: "solid",
          colors: ["#4371C3", "#A8D18E", "#BF4707"],
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "category",
          categories: this.chartDate,
          tickPlacement: "between",
          labels: {
            style: {
              fontSize: "10px",
              colors: "#808080",
            },
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (val) => {
              return val;
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "10px",
              colors: "#808080",
            },
            formatter: (val) => {
              return val;
            },
          },
        },
      },
    };
  },
  mounted() {
    this.updateData();
  },
  watch: {
    chartDate: function () {
      this.updateData();
    },
  },
  methods: {
    updateData() {
      this.$refs.chart.updateOptions({
        series: [
          {
            name: i18n.t("site-statistics-column-operator"),
            type: "column",
            data: this.controller,
          },
          {
            name: i18n.t("site-statistics-column-mem"),
            type: "column",
            data: this.support,
          },
          {
            name: i18n.t("site-statistics-prev-period"),
            type: "line",
            data: this.preData,
          },
        ],
        xaxis: {
          type: "category",
          categories: this.chartDate,
        },
      });
    },
  },
};
</script>
